<template>
<div class="main margin_bottom mobile_container_top_margin px-5">
    <div class="row">
        <div class="col-sm-12">
            <div v-show="loading">
                <LoadingIcon  :active="loading"/>
            </div>
            <div v-show="!loading" class="row">
                <div class="col-sm-12">
                    <h1>Cost of Goods Configuration</h1>
                </div>
            <div v-for="(field, key) in formMetadata" :key="key" class="col-sm-12">
                <div class="form-group">
                    <label>{{ field.label }}</label>
                    <input class="form-control number" type="text" v-model="form[key]" />
                </div>
            </div>
        <div class="col-sm-12">
            <button class="btn btn-primary" @click="save_cogs_config">Save</button>
        </div>
    </div>
        </div>
    </div>
</div>
</template>

<script>

import httpHelpers from "@/store/httpHelpers";
import LoadingIcon from "@/components/utils/LoadingIcon.vue";

export default {
    components: {LoadingIcon},
    data() {
        return {
            formMetadata: {
                galvanized_price_lb: { label: 'Galvanized Price by Pound' },
                aluminum_price_lb: { label: 'Aluminum Price by Pound' },
                galvalume_price_lb: { label: 'Galvalume Price by Pound' },
                stainless_steel_price_lb: { label: 'Stainless Steel Price by Pound' },
                one_and_qtr_in_ai_ft: { label: '1 1/4 Inch Angle Iron by Foot' },
                two_in_ai_ft: { label: '2 Inch Angle Iron by Foot' },
                tubesteel_ft: { label: 'Tube Steel by Foot' },
                lumber_1x4_ft: { label: 'Lumber 1x4 by Foot' },
                lumber_2x4_pc: { label: 'Lumber 2x4 per Piece' },
                gasket_ft: { label: 'Gasket by Foot' },
                perf_lining_sqft: { label: 'Perforated Lining by Square Foot' },
                acoustical_insulation_sqft: { label: 'Acoustical Insulation by Square Foot' },
                acoustical_sheetrock_sqft: { label: 'Acoustical Sheetrock by Square Foot' },
                duct_insulation_1in_sqft: { label: 'Duct Insulation 1 Inch by Square Foot' },
                duct_insulation_2in_sqft: { label: 'Duct Insulation 2 Inch by Square Foot' },
                duct_insulation_3in_sqft: { label: 'Duct Insulation 3 Inch by Square Foot' },
                rigid_insulation_1in_sqft: { label: 'Rigid Insulation 1 Inch by Square Foot' },
                rigid_insulation_2in_sqft: { label: 'Rigid Insulation 2 Inch by Square Foot' },
                rigid_insulation_3in_sqft: { label: 'Rigid Insulation 3 Inch by Square Foot' },
                foil_insulation_1in_sqft: { label: 'Foil Insulation 1 Inch by Square Foot' },
                foil_insulation_2in_sqft: { label: 'Foil Insulation 2 Inch by Square Foot' },
                foil_insulation_3in_sqft: { label: 'Foil Insulation 3 Inch by Square Foot' },
                kflex_insulation_1in_sqft: { label: 'K-Flex Insulation 1 Inch by Square Foot' },
                kflex_insulation_2in_sqft: { label: 'K-Flex Insulation 2 Inch by Square Foot' },
                kflex_insulation_3in_sqft: { label: 'K-Flex Insulation 3 Inch by Square Foot' },
                ltl_shipping_markup_pct: { label: 'LTL Shipping Markup Percentage' },
                truckload_shipping_markup_pct: { label: 'Truckload Shipping Markup Percentage' },
                gross_profit_margin: { label: 'Gross Profit Margin' },
                labor_cost_per_hour: { label: 'Labor Cost per Hour' },
                manufacturing_indirect_pct: { label: 'Manufacturing Indirect Percentage' },
            },
            form: {
                galvanized_price_lb: 0.00,
                aluminum_price_lb: 0.00,
                galvalume_price_lb: 0.00,
                stainless_steel_price_lb: 0.00,
                one_and_qtr_in_ai_ft: 0.00,
                two_in_ai_ft: 0.00,
                tubesteel_ft: 0.00,
                lumber_1x4_ft: 0.00,
                lumber_2x4_pc: 0.00,
                gasket_ft: 0.00,
                perf_lining_sqft: 0.00,
                acoustical_insulation_sqft: 0.00,
                acoustical_sheetrock_sqft: 0.00,
                duct_insulation_1in_sqft: 0.00,
                duct_insulation_2in_sqft: 0.00,
                duct_insulation_3in_sqft: 0.00,
                rigid_insulation_1in_sqft: 0.00,
                rigid_insulation_2in_sqft: 0.00,
                rigid_insulation_3in_sqft: 0.00,
                foil_insulation_1in_sqft: 0.00,
                foil_insulation_2in_sqft: 0.00,
                foil_insulation_3in_sqft: 0.00,
                kflex_insulation_1in_sqft: 0.00,
                kflex_insulation_2in_sqft: 0.00,
                kflex_insulation_3in_sqft: 0.00,
                ltl_shipping_markup_pct: 0.00,
                truckload_shipping_markup_pct: 0.00,
                gross_profit_margin: 0.00
            },
            loading: false
        }
    },

    methods: {
        get_current_cogs() {
            this.loading = true;
            let scope = this;
            httpHelpers.post('cogs/get_current_cogs_config', scope.form). then(function(res) {
                scope.assign_variables_from_server(res.data);
                scope.loading = false;
            });
        },
        save_cogs_config() {
            this.loading = true;
            let scope = this;
            httpHelpers.post('cogs/save_cogs_config', scope.form).then(function(res) {
                scope.assign_variables_from_server(res.data);
                scope.loading = false;
            });
        },
        assign_variables_from_server: function(data) {
            Object.entries(data).forEach(([key, value]) => {
                this.form[key] = value;
            });
        }
    },

    created() {
        this.get_current_cogs();
    }


}

</script>